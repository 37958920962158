<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="big-cta wrapper">
    <div class="big-cta-inner">
      <div v-if="content.pictures" class="big-cta__pictures">
        <GPicture
          v-for="(picture, index) in content.pictures"
          :key="`big-cta-picture-item-${index}`"
          class="big-cta__pictures__picture cover"
          :content="picture"
          sizes="5vw"
        />
      </div>

      <div>
        <GTitle :content="content.title" tag="h2" class="big-cta__title mt-m" />
        <GTitle :content="content.htmltext" class="big-cta__htmltext mt-s" />
      </div>

      <div class="mt-l">
        <Action
          v-if="content.link"
          :content="{ ...content.link, modifiers: ['reversed'] }"
          class="big-cta__link"
          @click="onClick()"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import type { BigCta } from '@/types'
</script>

<script setup lang="ts">
import { push } from '@/utils/tracking'

import type { PropType } from 'vue'

defineProps({
  content: {
    type: Object as PropType<BigCta>,
    required: true,
  },
})

const onClick = () => {
  push({
    event: 'contact_cta_click',
    ctaLocation: 'page content',
  })
}
</script>

<style lang="scss" scoped>
.big-cta {
  // prettier-ignore
  @include fluid(margin-block, (xxs: 2rem, xxl: 4rem));

  position: relative;
}

.big-cta-inner {
  @extend %br-default;

  // prettier-ignore
  @include fluid(padding-inline, (xxs: 2.4rem, xxl: 4.8rem));

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: $spacing * 4;
  background-color: $c-beige-medium;
}

.big-cta__pictures {
  display: flex;
  margin-left: 2.5rem;
}

.big-cta__pictures__picture {
  $list: 1 2 3 4;

  // prettier-ignore
  @include fluid(width, (xxs: 6rem, xxl: 8rem));

  position: relative;
  display: inline-flex;
  overflow: hidden;
  aspect-ratio: 1;
  border-radius: 50%;

  @each $i in $list {
    &:nth-child(#{$i}) {
      // top: -5px * $i;
      // transform: translateX(-2.5rem * $i);
      margin-left: -2.5rem;
    }
  }
}

.big-cta__title {
  margin-bottom: 0;
  color: $c-green-abr;
  text-align: center;
}

.big-cta__htmltext {
  margin-bottom: 0;
  text-align: center;
}

:deep(.action__label) {
  @extend %fw-semi;

  // prettier-ignore
  @include fluid(font-size, (xxs: 1.6rem, xxl: 2rem));

  // prettier-ignore
  @include fluid(line-height, (xxs: 2.2rem, xxl: 3rem));
}

:deep(.action__icon) {
  // prettier-ignore
  @include fluid(--icon-size, (xxs: 2rem, xxl: 2.8rem));
}
</style>
